div:focus,
span:focus {
  outline: none;
}

@media print {
  .navbar {
    display: flex !important;
  }
}

.MuiPopover-paper-60 {
  height: 400px;
  width: 600px;
}

.rbt.clearfix.open {
  width: 100%;
}
.rbt-input-wrapper {
  display: flex;
}
.rbt-token.rbt-token-removeable {
  margin: 0 10px;
}

.ag-react-container {
  width: 100%;
}

.ag-theme-material.double-header .ag-header-cell-resize {
  z-index: 0;
}

.ag-theme-material .ag-row-selected,
.ag-theme-material .ag-row-selected.ag-row-hover,
.ag-theme-material .ag-row-selected .highlight,
.ag-theme-material .ag-row-selected.ag-row-hover .highlight {
  color: unset;
  font-weight: normal;
}
.ag-theme-material .ag-row-selected .highlight {
  color: unset;
  font-weight: normal;
  background-color: unset;
}
.ag-theme-material .ag-row-selected {
  background-color: #dedede;
  font-weight: normal;
}

.ag-theme-material .ag-row:not(.ag-row-first) {
  border-width: 2px;
}

.ag-theme-material .ag-popup-editor {
  z-index: 40;
}

.ag-theme-material .ag-cell,
.ag-theme-material .ag-cell-label-container {
  line-height: unset;
  padding: 0 10px;
}

.ag-theme-material .ag-menu {
  border: 1px solid #cecece;
}

@media print {
  .ag-theme-material .ag-body-viewport {
    display: flex;
  }
}

.star-container .star {
  fill: #ff8802;
}

.MuiAutocomplete-popper.scope-select-popper {
  min-width: 20rem;
  left: 50px !important;
}
