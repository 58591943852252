@import './_bootstrap-overrides.scss';
@import './_table-overrides.scss';
@import "./fontawesome.scss";
@import "./fa-light.scss";
@import "./fa-regular.scss";
@import "./fa-solid.scss";

body {
  margin: 0;
  padding: 0;
}

// Buttons
.btn-clear {
  background-color: transparent;
}

.ReactTable.-highlight .rt-tbody .rt-tr-group:hover {
  background: rgba(0, 168, 155, 0.03);
}

.hidden {
  display: none;
}