$icons-path: '~ag-grid-community/src/styles/ag-theme-base/icons/';
$font-family: 'Open Sans';

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material.scss';

.ag-pinned-left-header,
.ag-pinned-right-header,
.ag-header-viewport {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.ag-theme-material {
  font: inherit;
  color: inherit;
  background-color: inherit;

  .ag-header {
    color: rgb(25, 25, 25);
    font: 700 12px "Roboto", sans-serif;

    .ag-header-cell-resize::after {
      border: none;
    }
  }

  .ag-header-row,
  .ag-row {
    display: flex;
    align-items: center;
  }

  .ag-row {
    border: none;
  }

  .ag-cell-last-left-pinned {
    border: none;
  }

  .ag-ltr .ag-cell-focus {
    border: none;
  }

  .ag-header-row {
    border: none !important;
  }

  .ag-row-odd.ag-row-hover {
    background-color: #eee;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-cell-label-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &.double-header {
    :not(.group-child).ag-cell-label-container {
      position: absolute;
      top: -50%;
    }

    .ag-header-cell-resize {
      right: 0;
      border-right: 1px solid #ccc;
    }

    .group-child {
      border-top: 1px solid #ccc;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    .ag-header-group-cell-with-group {
      height: 200%;
      position: absolute;
      top: 0;
    }

    .ag-header-group-cell {
      line-height: unset;
    }

    .ag-header-group-text {
      position: absolute;
      top: 18%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .ag-header-cell-label {
    display: flex;
    align-items: center;
    float: unset;
    span {
      height: unset;
    }
  }

  .ag-header-group-cell-with-group {
    justify-content: center;
    display: flex;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0;
  }

  .ag-cell-label-container {
    padding: 0 24px;
  }

  .ag-group-expanded .ag-icon-contracted:empty {
    background: none;
    &::before {
      content: "\f078";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 0.8rem;
      color: #888;
    }
  }

  .ag-icon-expanded {
    background: none;
    &::before {
      content: "\f077";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 0.8rem;
      color: #888;
    }
  }

  .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-menu,
  .ag-icon-menu {
    background: none;
    &::before {
      content: "\f142";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 1.1rem;
      padding: 0.5rem;
    }
  }

  .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-filter,
  .ag-icon-filter {
    background: none;
    position: relative;
    &::before {
      content: "\f0b0";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 0.7rem;
      position: absolute;
      top: 2px;
    }
  }
  .ag-tab-header .ag-tab.ag-tab-selected .ag-icon-columns,
  .ag-icon-columns {
    background: none;
    position: relative;
    &::before {
      content: "\f0db";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  .ag-header-cell-label .ag-icon-filter::before {
    top: -7px;
    position: absolute;
  }

  .ag-icon-tick {
    background: none;
    position: relative;
    &::before {
      content: "\f00c";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      font-size: 1.1rem;
      padding: 0.5rem;
      left: -6px;
      top: -1px;
    }
  }

  .ag-icon-pin {
    background: none;
    position: relative;
    &::before {
      content: "\f08d";
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 0.8rem;
      top: 7px;
      position: absolute;
      left: 4px;
    }
  }

  .ag-cell-label-container.child {
    border-top: 1px solid #ccc
  }

  .ag-header-group-cell {
    justify-content: center;
  }

  .ag-row-odd {
    background-color: #f9f9f9;
  }

  .ag-row-selected,
  .ag-row-selected.ag-row-hover,
  .ag-row-selected .highlight,
  .ag-row-selected.ag-row-hover .highlight {
    font-weight: normal;
  }

  .highlight {
    font-weight: 600;
    cursor: pointer;
  }
}