$enable-rounded: false;

$theme-colors: (
  primary: #00a89b,
  secondary: #8c8c8c,
  muted: #b3b3b3
);

$colors: (
  "gray": #b3b3b3
);

$font-family-base: "Open Sans";
@import 'bootstrap/scss/bootstrap.scss';


.dropdown-toggle:after {
  border: none;
  content: "\f078";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  font-size: 0.8rem;
  color: #888;
  position: absolute;
  right: 18px;
  top: 5px;
}